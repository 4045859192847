import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import ProgressBar from './ProgressBar';
import './navbarStyles.css';
import { supabase } from '../../utils/supabase';
import { FaBars } from 'react-icons/fa'; // Import the burger icon

const CustomNavbar = ({ progress, onBack, showProgress = true, title, session }) => {
  const logo = '/images/HourandcodeLOGO.png';

  const openCrispChat = () => {
    if (window.$crisp) {
      // Open the chat first
      window.$crisp.push(['do', 'chat:open']);
      
      // Set the pre-filled message after opening the chat
      window.$crisp.push(["set", "message:text", ["Hi! I’d love to share some feedback about my experience."]]);
    }
  };
  
  return (
    <Navbar bg="light" expand="lg" fixed="top" className="shadow-sm">
      <Navbar.Brand href="#" onClick={onBack}>
        {/* {title ? title : '←'} */}
        { title ? <img src={logo} alt="lock" className="logo" /> : '←'}
      </Navbar.Brand>
      {showProgress && (
        <div className="progress-container me-4 d-none d-md-block">
          <span>Progress:</span>
          <ProgressBar progress={progress} />
        </div>
      )}
      <Nav className="ml-auto d-none d-lg-flex">
          <Nav.Link href="/courses">Courses</Nav.Link>
      </Nav>
      <Nav className="ml-auto d-none d-lg-flex">
          <Nav.Link onClick={openCrispChat}>Send Feedback</Nav.Link>
      </Nav>
      { session && (
         <Nav className="ml-auto">
          <NavDropdown title={<FaBars />} id="basic-nav-dropdown" className="ml-auto  custom-dropdown">
            <NavDropdown.Item href="/mycourses">My courses</NavDropdown.Item>
            <NavDropdown.Item href="/account">Account</NavDropdown.Item>
            <NavDropdown.Item href="https://hourandcode.canny.io/feedback">Feature Request</NavDropdown.Item>
            <NavDropdown.Item onClick={openCrispChat}>Send Feedback</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#" onClick={() => supabase.auth.signOut()}>Log Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      ) }
      { !session && (
        <>
          <Nav className="ml-auto">
            <Nav.Link href="/authentication" className="border rounded">Log in</Nav.Link>
          </Nav>
        </>
        
      )}
    </Navbar>
  );
};

export default CustomNavbar;
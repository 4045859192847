import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../Navbar/CustomNavbar';
import './checkoutStyles.css';

const Checkout = ({session}) => {
  const navigate = useNavigate();
  const yearlyBuyButton = process.env.REACT_APP_YEARLY_BUY_BUTTON;
  const monthlyBuyButton = process.env.REACT_APP_MONTHLY_BUY_BUTTON;
  return (
    <div >
      <CustomNavbar className="mb-5" title="Hourandcode" session={session} showProgress={false} onBack={() => navigate(`/courses`)}  />
      
      <div className="container text-center border bg-dark text-white">
        <div className="row">
          <div className="col-12">
            <h3 className="">Unlock Progress Tracking & Access to All Lessons</h3>
            <p><b>You're currently enjoying free—but limited—access to Hourandcode</b>. Our paid plan gives you full access to all features & lessons.</p>
          </div>
        </div>
        <div className="row">
            <div className="col-12 mt-5">
                <div className="row d-flex justify-content-center">
                  {/* <span className="col-12 col-lg-6 mb-4">
                    { yearlyBuyButton &&
                      <>
                        <stripe-buy-button
                          buy-button-id={yearlyBuyButton}
                          publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                          customer-email={session?.user?.email}
                          client-reference-id={session?.user?.id}
                        >
                        </stripe-buy-button>
                        <div>$69/mo (30% OFF)</div>
                      </>
                    }
                    
                  </span> */}
                  <span className="col-12 col-lg-6 col-6">
                    {monthlyBuyButton && 
                      <stripe-buy-button
                      buy-button-id={monthlyBuyButton}
                      publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                      customer-email={session?.user?.email}
                      client-reference-id={session?.user?.id}
                    >
                    </stripe-buy-button>
                    }
                    <br/>
                    <p className="mt-4">– or –</p>
                    <a href={process.env.REACT_APP_YEARLY_STRIPE_LINK}className="btn btn-dark border mt-2">Get 30% Off – Switch to Annual</a>
                  </span>
                  <hr className="mt-4"/>
                  <p className="mt-4 text-warning" style={{fontSize: '20px'}}><b>100% Satisfaction Guaranteed</b> – If you're not confident in your skills within 30 days, we’ll provide free personalized coaching to help you succeed.</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout;
// src/components/CourseList.js
import React from 'react';
import { Link } from 'react-router-dom';
import CustomNavbar from '../Navbar/CustomNavbar';
import './courseListStyles.css';
import { courses } from '../courseDataLoader'; // Importing shared course data
import { Tooltip } from 'react-tooltip'; // Import Tooltip


const CourseList = ({session}) => {
  return (
    <div className="pt-4">
      <CustomNavbar title="Hourandcode" session={session} showProgress={false} />
      <div className="container lg-col-6 mt-4">
        {/* <h1>Courses</h1> */}
        <div className="course-list mt-4 mb-4">
          {courses.map((course) => (
            <div className="course-card shadow-sm" key={course.id}>
              <img src={course.thumbnail} alt={`${course.title} thumbnail`} className="course-thumbnail" />
              <div className="course-info">
                <h2>
                  {course.title}
                  { course.live === "true" ? <span className="live-badge badge text-bg-warning" data-tooltip-id="live-tooltip" data-tooltip-content="Exclusive Weekly Office Hour">Early Access</span> : '' }
                </h2>
                <Tooltip id="live-tooltip" className="tooltip"/>
                <p>{course.description}</p>
                {course.live == "true" ? <p className="in-development">New lessons weekly ✨</p> : '' }
                <Link to={`/courses/${course.id}`}>
                  <button className="btn btn-primary">Start →</button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CourseList;
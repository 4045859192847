import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import './inputContentStyles.css';
import Prism from 'prismjs';
import { supabase } from '../../../utils/supabase';// Import Supabase client
import 'prismjs/themes/prism.css'; // Ensure Prism CSS is imported
import 'prismjs/components/prism-ruby'; // Import Ruby component
import 'prismjs/components/prism-python'; // Import Ruby component
import 'prismjs/components/prism-go'; // Import Go component
import 'prismjs/components/prism-sql';
import '../../../styles/prism-monokai.css'; // Import the local Monokai theme CSS
import ReactMarkdown from 'react-markdown';

const InputContent = ({ onComplete, courseId, stepIndex, step, isCorrect, session, lessonId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [hasPassed, setHasPassed] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [userCourse, setUserCourse] = useState(null);
  const [fetchedContent, setFetchedContent] = useState(false);  // Ensure this is defined
  // const inputRef = useRef(null); // Create a ref for the input field
  const [placeholder, setPlaceholder] = useState('Enter your text here');

  useEffect(() => {
    const fetchSavedProgress = async () => {
      if (fetchedContent === true){
        // console.log('no inputContent fetch needed')
        return;
      }
      
      const { data: sessionData } = await supabase.auth.getSession();
      if (sessionData) {
        if (!session || !session.user) {
          return; // Exit if session is not available
        }

        // Fetch UserCourse based on course_id
        const { data: userCourseData, error: userCourseError } = await supabase
          .from('UserCourses')
          .select('*')
          .eq('user_id', session.user.id)
          .eq('course_id', courseId)
          .single();


        if (userCourseError) {
          console.error("Error fetching UserCourse:", userCourseError);
          return; // Exit if there's an error
        }

        if (userCourseData) {
          setUserCourse(userCourseData);
          // Fetch saved progress from UserCourseLessons
          const { data: lessonData, error: lessonError } = await supabase
              .from('UserCourseLessons')
              .select('*')
              .eq('user_course_id', userCourseData.id)
              .eq('lesson_id', lessonId) // Assuming step.id is the lessonId
              .single();

          if (lessonError && lessonError.code !== "PGRST116") {
              console.error("Error fetching lesson progress:", lessonError);
              return; // Exit if there's an error
          } else if (lessonError && lessonError.code === "PGRST116") {
            // console.log("error::::: PGRST116")
            // console.log("..userCourseData: " + JSON.stringify(userCourseData))
            // console.log("step.id: " + step.id)
          }

          if (lessonData) {
              const stepProgress = lessonData.progress[stepIndex];
              if (stepProgress) {
                  setInputValue(stepProgress.input || ''); // Load saved input if available
                  setHasPassed(stepProgress.result === true); // Check if result is true
              }
          }

          setFetchedContent(true)
        }
      }
    };

    fetchSavedProgress();
    Prism.highlightAll(); // Ensure Prism highlights the code after rendering
}, [step, courseId, stepIndex, session, lessonId, fetchedContent]);

  useEffect(() => {
    Prism.highlightAll(); // Highlight code blocks after component mounts and updates
  }, [step.content]);

  // const resetInput = () => {
  //   setInputValue('');
  //   setErrorMessage('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setErrorMessage('');
  };

  const handleSubmit = async () => {
    setErrorMessage('');

    const isMatch = checkInputMatch(); // Check if the input matches the expected result
    const error = isMatch ? '' : getErrorMessage(); // Get error message if input is incorrect

    // Update local state
    setErrorMessage(error);

    // Prepare the progress update object
    if (error === '') {
        const progressUpdate = {
            input: inputValue,
            result: isMatch, // This will be true or false based on the input check
        };
        if (session || session?.user) {
          // Store progress in Supabase
          const { error: updateError } = await supabase
              .from('UserCourseLessons')
              .upsert({
                  user_course_id: userCourse.id, // Ensure userCourseData is available
                  lesson_id: step.id, // Assuming step.id is the lessonId
                  progress: { [stepIndex]: progressUpdate }
              });

          if (updateError) {
              console.error("Error updating progress:", updateError);
              return; // Exit if there's an error
          }
        }
        // Call onComplete with the progress update
        setHasPassed(true);
        onComplete(progressUpdate); // Ensure this is always called with a valid object
    }

    Prism.highlightAll();
  };


  const checkInputMatch = () => {
    const regex = step.expectedRegex ? new RegExp(step.expectedRegex) : null;
    const expectedResults = Array.isArray(step.expectedResult) ? step.expectedResult : [step.expectedResult];
    
    // console.log("regex: " + regex);
    // console.log("inputValue.trim(): " + inputValue.trim())
    // const regexMatch = regex ? regex.test(inputValue.trim()) : false;
    // console.log('Regex Match:', regexMatch);

    return expectedResults.includes(inputValue.trim()) || (regex && regex.test(inputValue.trim()));
  };

  const getErrorMessage = () => {
    const expected = step.expectedResult[0]
    const userInput = inputValue.trim();

    if (expected !== userInput) {
      const expectedLines = expected.split('\n');
      const userLines = String(userInput).split('\n');
      let mismatchFound = false;

      expectedLines.forEach((line, index) => {
          if (line !== userLines[index]) {
              console.log(`Mismatch at line ${index + 1}. Expected: ${String(line).trim() || 'Empty Line'}, Got: ${String(userLines[index]).trim() || 'Empty Line'}`);
              mismatchFound = true;
          }
      });

      // if (mismatchFound) {
      //     return;
      // }
    }

    const errorMessages =  step.errorMessages || {}; 
    return errorMessages[userInput === "" ? 'default' : userInput] || "Incorrect. Try again.";
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };
  // Function to parse content and extract code blocks
  const parseContent = (text) => {
    const parts = text.split(/(```[\s\S]*?```)/g);
    return parts.map((part, index) => {
      if (part.startsWith('```')) {
        // Code block
        const match = part.match(/```(.*?)\n([\s\S]*?)```/);
        if (match) {
          const language = match[1].trim();
          const code = match[2].trim();
          return (
            <pre key={index} className={`language-${language}`}>
              <code className={`language-${language}`}>
                {code}
              </code>
            </pre>
          );
        }
      } else {
        // Regular text
        return (
          <span key={index}>
            {part.split('\n').map((line, idx) => (
              <React.Fragment key={index + '-' + idx}>
                {line.split('`').map((segment, subIdx) => (
                  subIdx % 2 === 1
                    ? <code key={index + '-' + idx + '-' + subIdx}>{segment}</code> // Use a unique key
                    : segment
                ))}
                <br />
              </React.Fragment>
            ))}
          </span>
        );
      }
      return null;
    });
  };
  
  const PassIcon = () => (
    <svg aria-label="Pass Icon" aria-hidden="false" className="ml-2" height="24" role="img" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <title>Pass Icon</title>
      <circle cx="12" cy="12" r="12" fill="#4CAF50" />
      <path d="M8.75 17.882l-4.359-4.359a.75.75 0 0 1 1.06-1.06l4.006 4.005 9.005-9.006a.75.75 0 0 1 1.061 1.061l-9.359 9.359a1 1 0 0 1-1.414 0z" fill="#FFF" />
    </svg>
  );

  // const renderLineNumbers = () => {
  //   const lines = inputValue.split('\n').length;
  //   return Array.from({ length: lines }, (_, i) => i + 1).map(lineNumber => (
  //     <div key={lineNumber} className="line-number">
  //       {lineNumber}
  //     </div>
  //   ));
  // };

  const handleFocus = () => {
    setPlaceholder(step.placeholder || 'Type your code...');
  };

  const handleBlur = () => {
    setPlaceholder('> Write your code here');
  };

  return (
    <div className="input-content mb-3 p-3">
      <div>
        {parseContent(step.content)} 
      </div>
      <br/>
      <p>{(hasPassed) && (
        <>Your Submission:</>
        )}</p>
      <div className="d-flex flex-column">
        {((!hasPassed)) &&  (
          <>
            <div className="col-12 mb-2">
              {step.multiline !== "true" &&
               <div className="code-input-wrapper">
                {/* <div className="line-numbers">
                  {renderLineNumbers()}
                </div> */}
                <Form.Control
                  type="text"
                  className={`language-${step.language || 'plaintext'} codeblock`}
                  placeholder={placeholder}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  isInvalid={errorMessage !== ''}
                />
                </div>
              }
              {step.multiline === "true" &&
                <div className="code-input-wrapper">
                  {/* <div className="line-numbers">
                    {renderLineNumbers()}
                  </div> */}
                  <Form.Control
                    as="textarea"
                    rows={15}
                    className={`language-${step.language || 'plaintext'} codeblock`}
                    placeholder={placeholder}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={inputValue ? inputValue : step.initialValue}
                    onChange={handleInputChange}
                    isInvalid={errorMessage !== ''}
                  />
                </div>
              }
            </div>
            <div className="col-12 d-flex justify-content-end">
              {/* <Button className="dflex me-2 btn-warning ">
                Ask Community 
              </Button> */}
              <Button className="d-flex" onClick={handleSubmit}>
                Run
                <svg aria-label="Return Icon" aria-hidden="false" className="ml-1" height="24" role="img" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <title>Return Icon</title>
                  <g aria-hidden="true">
                    <path d="M16.25 5a.75.75 0 0 1 .75.75v9.5a.75.75 0 0 1-.648.743L16.25 16H7.637l1.068 1.069a.75.75 0 0 1-1.06 1.06L5.22 15.706a.75.75 0 0 1 0-1.06l2.424-2.425a.75.75 0 0 1 1.061 1.06L7.483 14.5H15.5V5.75a.75.75 0 0 1 .75-.75z" fill="#FFF"></path>
                  </g>
                </svg>
              </Button>
            </div>
          </>
        )}
        {(hasPassed) && (
          <>
          <div className={`language-${step.language || 'plaintext'} align-items-center codeblock w-100`}>
            {step.multiline === 'true' && (
              <pre className="custom-pre">
                { step.expectedResult[0].split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                      <code  key={index}>
                        {line}
                      </code>
                      <br />
                  </React.Fragment>
                ))}
              </pre>
            )}
            {step.multiline !== 'true' &&
            <div className="code-input-wrapper">
              {/* <div className="line-numbers">
                {renderLineNumbers()}
              </div> */}
              <span className={`language-${step.language || 'plaintext'} codeblock 100w`}>{inputValue ? inputValue : step.initialValue}<PassIcon className="justify-content-end" /></span>
            </div>
            }
          </div>
          </>
        )}
      </div>
      {hasPassed && step.returnedValue !== undefined && (
        <div className="">
          <p className="code-output">code output</p>
          <hr/>
          <span className="submitted-state text-content">
            <ReactMarkdown>
            {step.returnedValue}
            </ReactMarkdown>
          </span>
        </div>
      )}
      {!isCorrect && (
        <div className="mt-2">
          <Form.Control.Feedback type="invalid" className="d-block">
            {errorMessage}
          </Form.Control.Feedback>
        </div>
      )}
      {/* {hasPassed && (
        <div className="mt-2 d-flex justify-content-end">
          <Button className="ml-2 btn-success" onClick={handleRedo}>
            Redo <RedoIcon />
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default InputContent;
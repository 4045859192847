import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CustomNavbar from '../Navbar/CustomNavbar';
import CoverImage from './CoverImage';
import './courseStyles.css';
import { supabase } from '../../utils/supabase';
import INSTRUCTOR_IMAGE from '../../images/instructor-rich.jpeg'

const Course = ({session, account}) => {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const lockSVG = '/images/lock.svg';
  const [lessonCompleted, setLessonCompleted] = useState({}); // Initialize as an empty object
  const [currentUser, setCurrentUser] = useState(null);
  const [lessonsInProgress, setLessonsInProgress] = useState({});
  const [viewType, setViewType] = useState('hour'); // Default to 'hour' view
  const [studySchedule, setStudySchedule] = useState([]);
  const [studyTimePerDayHours, setStudyTimePerDayHours] = useState(1);
  const [courseData, setCourseData] = useState(null); // State to store course data

  // Fetch course data from CDN
  const fetchCourseData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/course-json-public/${courseId}/courseData.json`);
      // Debug
      // const response = await fetch('http://127.0.0.1:3007/json-data');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCourseData(data);
    } catch (error) {
      console.error('Error fetching course data:', error);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, [courseId]);

  // Assuming levels is already defined in your component
  // const studyTimePerDayHours = 1; // Example: 2 hours per day
  const fetchStudyPace = async () => {
    if (session && session && session.user){
      const { data, error } = await supabase
        .from('Accounts')
        .select('study_pace')
        .eq('user_id', session?.user.id)
        .single();
    
      if (error) {
        console.error('Error fetching study pace:', error.message);
      } else {
        const studyPace = data?.study_pace || 1; // Default to 1 if study_pace is not available
        setStudyTimePerDayHours(studyPace);
        // const schedule = groupLessonsByStudyTime(courseData.levels, studyPace);
        // setStudySchedule(schedule);
      }
    }
  };

  // Fetch course data and study pace
  useEffect(() => {
    const fetchData = async () => {
      await fetchCourseData();
      await fetchStudyPace();
    };
    fetchData();
  }, [courseId, session?.user.id]);

  // Update study schedule when course data or study time per day changes
  useEffect(() => {
    if (courseData && studyTimePerDayHours) {
      const schedule = groupLessonsByStudyTime(courseData.levels, studyTimePerDayHours);
      setStudySchedule(schedule);
    }
  }, [courseData, studyTimePerDayHours]);


  // useEffect(() => {
  //   const schedule = groupLessonsByStudyTime(courseData.levels, studyTimePerDayHours);
  //   setStudySchedule(schedule);
  // }, [courseData.levels]);

  const toggleView = () => {
    setViewType(prevView => (prevView === 'hour' ? 'module' : 'hour'));
  };

  useEffect(() => {
    if (session && session.user && (account && (account.stripe_subscription_status === 'active'))) {
      const fetchLessonsInProgress = async () => {
        console.log("===> fetchLessonsInProgress")
        const { data: userCourseData, error: userCourseError } = await supabase
        .from('UserCourses')
        .select('id') // Assuming 'id' is the user_course_id
        .eq('user_id', session.user.id)
        .eq('course_id', courseId)
        .single();

        if (userCourseError && (userCourseError.code !== 'PGRST116')) {
            console.error("Error fetching UserCourse:", userCourseError);
            return; // Exit if there's an error
        } else if (userCourseError && (userCourseError.code === 'PGRST116')) {
          // console.log("No UserCourse found, creating a new one.");
          // Create UserCourse if not found
          console.log(" Creating UserCourse if not found")
          const { error: insertError } = await supabase
            .from('UserCourses')
            .insert([
              {
                user_id: session.user.id,
                course_id: courseId,
                title: courseData.title,
                completed: false
              }
            ]);
  
          if (insertError) {
            console.error("Error creating UserCourse:", insertError);
            return; // Exit if there's an error
          }
        }

        const { data, error } = await supabase
          .from('UserCourseLessons')
          .select('lesson_id, progress')
          .eq('user_id', session.user.id)
          .eq('user_course_id', userCourseData.id)
          .not('progress', 'is', null);

        if (error) {
          console.error('Error fetching lessons in progress:', error);
          return;
        }

        const inProgressLessons = data.reduce((acc, lesson) => {
          acc[lesson.lesson_id] = true;
          return acc;
        }, {});

        // console.log('inProgressLessons: ' + JSON.stringify(inProgressLessons))

        setLessonsInProgress(inProgressLessons);
      };

      fetchLessonsInProgress();
    }

  }, [session, courseId]);

  useEffect(() => {
    const fetchUserData = async () => {
        if (session && session.user && (account && (account.stripe_subscription_status === 'active'))) {
            const { email } = session.user;

            // Query the Accounts table for the authenticated user's email
            const { data, error } = await supabase
                .from('Accounts')
                .select('paid')
                .eq('email', email)
                .single(); // Get a single record

            if (error) {
                console.error("Error fetching user data:", error);
                setCurrentUser({ paid: false }); // Default to false if there's an error
            } else if (data) {
                setCurrentUser({ paid: data.paid }); // Set the paid status from the fetched data
            }
        } else {
            setCurrentUser({ paid: false }); // Default if session is not available
        }
    };

    fetchUserData();
}, [session]); // Depend on session to fetch user data when it changes

  
  useEffect(() => {
    if (courseData && (session && session.user && (account && (account.stripe_subscription_status === 'active')))){
      const fetchLessonCompletion = async () => {
        const { data: sessionData } = await supabase.auth.getSession();
        if (sessionData) {
          if (!session || !session.user) {
            return; // Exit if session is not available
          }
  
          const { data, error } = await supabase
            .from('UserCourses')
            .select('lessons_completed')
            .eq('user_id', session.user.id) // Get user ID from session
            .eq('course_id', courseId) // Add this line to filter by course_id
            .single();
      
          if (error || !data) {
            console.error('Error fetching lesson completion status:', error);
            return;
          }
      
          setLessonCompleted(data.lessons_completed || {});
        }
      };
    
      fetchLessonCompletion();
    }
  }, [courseData]);

  // useEffect(() => {
  //   if (Object.keys(lessonCompleted).length > 0) {
  //     // Call isLessonCompleted or perform any actions here
  //     const completed = isLessonCompleted(lessonId);
  //     console.log(`Is lesson ${lessonId} completed?`, completed);
  //   }
  // }, [lessonCompleted]); // Run this effect when lessonCompleted changes

  const PassIcon = (
    <>
      &nbsp;
      <svg aria-label="Pass Icon" aria-hidden="false" className="ml-2" height="24" role="img" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <title>Pass Icon</title>
        <circle cx="12" cy="12" r="12" fill="#4CAF50" />
        <path d="M8.75 17.882l-4.359-4.359a.75.75 0 0 1 1.06-1.06l4.006 4.005 9.005-9.006a.75.75 0 0 1 1.061 1.061l-9.359 9.359a1 1 0 0 1-1.414 0z" fill="#FFF" />
      </svg>
    </>
  );

  const isLessonCompleted = (lessonId) => {
    return !!lessonCompleted[lessonId];
  }

  const isLessonInProgress = (lessonId) => {
    // console.log('isLessonInProgress: ' + !!lessonsInProgress[lessonId])
    return !!lessonsInProgress[lessonId];
  };

  // Function to count words
  const countWords = (text) => {
    return text ? text.split(/\s+/).filter(word => word.length > 0).length : 0;
  };



  // Function to estimate time for a lesson
  const estimateTimeForLesson = (lesson) => {
    const averageWPM = 200; // Average reading speed in words per minute
    const codeStepTime = 60; // Time per code step in seconds
    const inputStepTime = 120; // Time per input step in seconds

    const totalWords = lesson.steps.reduce((total, step) => {
      if (step.type === 'text' || step.type === 'input') {
        return total + countWords(step.content);
      }
      return total;
    }, 0);

    const codeSteps = lesson.steps.filter(step => step.type === 'code').length;
    const inputSteps = lesson.steps.filter(step => step.type === 'input').length;

    const readingTimeMinutes = totalWords / averageWPM;
    const codeStepsTimeMinutes = (codeSteps * codeStepTime) / 60;
    const inputStepsTimeMinutes = (inputSteps * inputStepTime) / 60;

    return readingTimeMinutes + codeStepsTimeMinutes + inputStepsTimeMinutes;
  };

  // Function to group lessons by study time per day (in hours) and include estimated time
  const groupLessonsByStudyTime = (levels, studyTimePerDayHours) => {
    const studyTimePerDayMinutes = studyTimePerDayHours * 60; // Convert hours to minutes
    const studySchedule = [];
    let currentDay = [];
    let currentDayTime = 0;

    Object.keys(levels).forEach(levelKey => {
      const level = levels[levelKey];

      level.lessons.forEach(lesson => {
        const estimatedTime = estimateTimeForLesson(lesson);
        const lessonWithTime = { ...lesson, estimatedTime, levelId: levelKey };

        if (currentDayTime + estimatedTime > studyTimePerDayMinutes) {
          studySchedule.push(currentDay);
          currentDay = [];
          currentDayTime = 0;
        }

        currentDay.push(lessonWithTime);
        currentDayTime += estimatedTime;
      });
    });

    // Add the last day if it has any lessons
    if (currentDay.length > 0) {
      studySchedule.push(currentDay);
    }

    return studySchedule;
  };

  return (
    <div style={{padding: '20px'}}>
      <CustomNavbar title="Hourandcode" session={session} showProgress={false} onBack={() => navigate(`/courses`)}  />
      {courseData && (
        <>
          <div className="container mt-4 col-12">
            <CoverImage 
              backgroundImage={courseData.coverImage.backgroundImage}
              thumbnail={courseData.coverImage.thumbnail}
              title={courseData.title}
              subtitle={courseData.coverImage.subtitle}
            />
          </div>
          <div className="container mt-4 col-12">
            <div className="row">
              <div className="container mt-4 pt-1 col-md-3 col-12">
                <h2>About This Course</h2>
                <p><span className="count-about"><b>{Object.keys(courseData.levels).length}</b> Modules</span> <span className="count-about"><b>{Object.values(courseData.levels).reduce((total, level) => total + level.lessons.length, 0)}</b> Lessons</span></p>
                <p className="course-about">{courseData.about}</p>
                <h5>Prerequisites</h5>
                <p className="course-about">{courseData.prerequisite && courseData.prerequisite.split('\n').map((line, index) => (
                  <>
                    <span key={index}>
                    {line}
                    </span><br/>
                  </>
                ))}</p>
                <h5>Instructor</h5>
                <p className="instructor-info course-about">
                    <img 
                        src={INSTRUCTOR_IMAGE}
                        alt="Instructor Profile"
                        className="profile-image"
                    />
                    <p className="instructor-name">Richardson Dackam</p>
                </p>
                { courseData.live && (
                  <>
                    <h5>Office Hours <span className="live-badge badge text-bg-danger">WEEKLY SESSION</span></h5>
                    <p className="course-office-hour course-about d-grid">
                      <p>Book a time to get personalized answers to your questions, discuss the course, and share your feedback. Your insights will directly shape future lessons and enhance the course experience.</p>
                      <a href={courseData.officeHourUrl} className="btn btn-dark">Schedule Your Office Hour</a>
                    </p>
                  </>
                )}
              </div>
              <div className="container pt-1 mt-4 col-md-9 col-12">
                {/* Toggle Buttons */}
                <div className="text-center">
                  <div className="view-toggle">
                    <Button onClick={toggleView} className={viewType === 'hour' ? 'active btn-light border' : 'btn-light border'}>Hour View</Button>
                    <Button onClick={toggleView} className={viewType === 'module' ? 'active btn-light border' : 'btn-light border'}>Module View</Button>
                  </div>
                </div>

                {/* Conditional Rendering based on viewType */}
                {viewType === 'hour' ? (
                  <div className="container pt-1 mt-5 col-md-9 col-12">
                    <p className="progress-insight">Complete this course in <b className="text-dark">{studySchedule.length}</b> day{studySchedule.length > 1 ? 's' : ''} with just <b className="text-dark">{studyTimePerDayHours}</b> hour{studyTimePerDayHours > 1 ? 's' : ''} a day. <a href="#" onClick={() => navigate(`/account`)} className="text-primary text-decoration-none"><b>Adjust Study Pace</b></a></p>
                    <hr/>
                    {studySchedule.map((day, dayIndex) => (
                      <div key={dayIndex} className="day-schedule">
                        <h3>Day {dayIndex + 1}</h3>
                        <div className="lessons-list mt-2 mb-4">
                          {day.map((lesson, index) => (
                            <div key={index} className="lesson-item">
                              <div className="lesson-info">
                                <span className="lesson-title">
                                  {lesson.title} <span className="text-primary">({Math.round(lesson.estimatedTime)} min)</span>
                                  {lesson.locked === 'true' && !currentUser?.paid ? (
                                    <span>
                                      <img src={lockSVG} alt="lock" className="lock" />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  <span>
                                    {lessonCompleted && Object.keys(lessonCompleted).length >= 0 ? (
                                      isLessonCompleted(lesson.id) ? PassIcon : ""
                                    ) : (
                                      " Loading..." // or any placeholder while loading
                                    )}
                                  </span>
                                </span>
                              </div>
                              <Link to={`/courses/${courseId}/${lesson.levelId}/${lesson.id}`}>
                                {lessonCompleted && Object.keys(lessonCompleted).length >= 0 ? (
                                  isLessonCompleted(lesson.id) ? (
                                    <Button variant="secondary">View</Button>
                                  ) : isLessonInProgress(lesson.id) ? (
                                    <Button variant="dark">Continue</Button>
                                  ) : (
                                    <Button variant="primary">Start</Button>
                                  )
                                ) : (
                                  <Button variant="primary">Start</Button>
                                )}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="container pt-1 mt-4 col-md-9 col-12src/components/Course/Course.js
                  ">
                    {Object.entries(courseData.levels).map(([levelId, level]) => (
                      <div key={levelId} className="level-container mb-4">
                        <h2>{level.title}</h2>
                        <div className="lessons-list">
                          {level.lessons.map((lesson, index) => (
                            <div key={index} className="lesson-item">
                              <div className="lesson-info">
                                <span className="lesson-title">
                                  {lesson.title}
                                  {lesson.locked === 'true' && !currentUser?.paid ? (
                                    <span>
                                      <img src={lockSVG} alt="lock" className="lock" />
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                  <span>
                                    {lessonCompleted && Object.keys(lessonCompleted).length >= 0 ? (
                                      isLessonCompleted(lesson.id) ? PassIcon : ""
                                    ) : (
                                      " Loading..." // or any placeholder while loading
                                    )}
                                  </span>
                                </span>
                              </div>
                              <Link to={`/courses/${courseId}/${levelId}/${lesson.id}`}>
                                {lessonCompleted && Object.keys(lessonCompleted).length >= 0 ? (
                                  isLessonCompleted(lesson.id) ? <Button variant="secondary">View</Button> : (isLessonInProgress(lesson.id) ? <Button variant="dark">Continue</Button> : <Button variant="primary">Start</Button>)
                                ) : (
                                  <Button variant="primary">Start</Button>
                                )}
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Course;
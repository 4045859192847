import React, { useEffect, useState } from 'react';
import './showReferenceStyles.css';
import { useParams } from 'react-router-dom';
import { marked } from 'marked'; // Import marked
import Prism from 'prismjs';
import 'prismjs/themes/prism.css'; // Ensure Prism CSS is imported
import 'prismjs/components/prism-ruby'; // Import Ruby component
import 'prismjs/components/prism-python'; // Import Ruby component
import "prismjs/components/prism-markup-templating.js";
import 'prismjs/components/prism-php'; 
import '../../../styles/prism-monokai.css'; // Import the local Monokai theme CSS


const ShowReference = () => {
  const { courseId, levelId, lessonId } = useParams();
  const [lesson, setLesson] = useState(null);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/course-json-public/${courseId}/courseData.json`);
        // Debug
        // const response = await fetch('http://127.0.0.1:3007/json-data');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const level = data.levels[levelId];
        const lesson = level.lessons.find(l => l.id === lessonId);
        setLesson(lesson);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchCourseData();
  }, [courseId, levelId, lessonId]);

  useEffect(() => {
    if (lesson) {
      Prism.highlightAll();
    }
  }, [lesson]);

  return (
    <div className="reference-popup p-4 bg-light border">
      <h1 className="reference-title">Reference</h1><br/>
      <div className="pb-4" dangerouslySetInnerHTML={{ __html: marked(lesson?.referenceContent || '') }} /> {/* Convert markdown to HTML */}
    </div>
  );
};

export default ShowReference;
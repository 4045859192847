import React, {useState, useEffect} from  "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom'; // Add this import
import CourseList from "./components/CourseList/CourseList";
import Course from "./components/Course/Course";
import Lesson from "./components/Lesson/Lesson";
import Account from "./components/Account/Account";
import Pricing from "./components/Pricing/Pricing";
import Checkout from "./components/Checkout/Checkout";
import Return from "./components/Checkout/Return";
import Authentication from "./components/Authentication/Authentication";
import UserCourseList from "./components/UserCourseList/UserCourseList";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/prism-monokai.css';
import './styles.css';
import { supabase } from './utils/supabase';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'; // Update this import
import posthog from 'posthog-js'; // Add this import

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  environment: process.env.REACT_APP_ENV,
};

// Initialize PostHog
if (process.env.REACT_APP_ENV === 'production') {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
  posthog.capture('my event', { property: 'value' })
  console.log('posthog is live')
}


// function TestError() {
//   const a = null
//   return a.hello()
// }

// Function to load Google Analytics script
const loadGoogleAnalytics = () => {
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-YD10X05EPZ';
  document.head.appendChild(script1);

  const script2 = document.createElement('script');
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-YD10X05EPZ');
  `;
  document.head.appendChild(script2);
};

const App = () => {
  const [session, setSession] = useState(null)
  const [account, setAccount] = useState(null); // Add state for account
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const fetchSessionAndAccount = async () => {
      setLoading(true); 
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);


      if (session) {
        const { data: accountData } = await supabase
          .from('Accounts')
          .select('*')
          .eq('email', session.user.email)
          .single();

        setAccount(accountData);
      }
      setLoading(false);
    };

    fetchSessionAndAccount();

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      // Fetch account again on auth state change
      if (session) {
        fetchSessionAndAccount();
      } else {
        setAccount(null);
      }
    })
  }, [])

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "5e2e26e8-6d07-4e96-b6ca-7cd5667e84c2";
    (function() {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  useEffect(() => {
    if (session) {
      posthog.identify(session.user.id, {
        email: session.user.email,
      });
    }
  }, [session]);

  // console.log("account: " + JSON.stringify(account))
  // console.log("account?: " + (account && (account.stripe_subscription_status === 'active')))
  
  useEffect(() => {
    loadGoogleAnalytics();
  }, []);

  if (loading) {
    return <div>...</div>; // Render loading state
  }

  return (
    <RollbarProvider config={rollbarConfig}> 
      <ErrorBoundary>
        <Router>
          <div className="app-container">
            <Routes>
              <Route path="/" element={<CourseList session={session}/>} />
              <Route path="/authentication" element={session ? <Navigate to="/courses" /> : <Authentication session={session} />} />
              {/* <Route path="/mycourses" element={session ? (account && (account.stripe_subscription_status === 'active') ? <UserCourseList session={session} /> : <Navigate to="/checkout" />) : <UserCourseList />} /> */}
              <Route path="/mycourses" element={<UserCourseList session={session} account={account} />} />
              <Route path="/account" element={session ? <Account session={session} /> : <UserCourseList />} />
              <Route path="/checkout" element={<Checkout session={session} />} />
              <Route path="/return" element={<Return session={session} />} />
              <Route path="/upgrade" element={<Pricing session={session} />} />
              {/* <Route path="/courses" element={session ? (account && (account.stripe_subscription_status === 'active') ? <CourseList session={session} /> : <Navigate to="/checkout" />) : <CourseList />} /> */}
              <Route path="/courses" element={<CourseList session={session} /> } />
              {/* <Route path="/courses/:courseId" element={session ? (account && account.paid ? <Course session={session} /> : <Navigate to="/checkout" />) : <Course />} /> */}
              <Route path="/courses/:courseId" element={<Course session={session} account={account} />} />
              {/* <Route path="/courses/:courseId/:levelId/:lessonId" element={session ? (account && (account.stripe_subscription_status === 'active') ? <Lesson session={session} /> : <Navigate to="/checkout" />) : <Lesson />}/> */}
              <Route path="/courses/:courseId/:levelId/:lessonId" element={<Lesson session={session} account={account} />} />
            </Routes>
          </div>
        </Router>
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default App;
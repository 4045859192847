import React from 'react';
import './textContentStyles.css';
import ReactMarkdown from 'react-markdown';
import '../../../styles/prism-monokai.css'; // Import the local Monokai theme CSS


const TextContent = ({ content }) => {
  return (
    <div className="text-content">
      <p>
        <div className="text-content">
          <ReactMarkdown>
            {content}
          </ReactMarkdown>
        </div>
      </p>
    </div>
  );
};

export default TextContent;
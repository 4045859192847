import React from 'react';
import './imageContentStyles.css'; // Create this CSS file for styles

const ImageContent = ({ src, alt }) => {
  return (
    <div className="image-content mb-4">
      <img src={src} alt={alt} className="image" />
    </div>
  );
};

export default ImageContent;
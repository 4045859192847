// src/components/CourseList.js
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomNavbar from '../Navbar/CustomNavbar';  
import './userCourseListStyles.css';
import { courses } from '../courseDataLoader'; // Importing shared course data
import { supabase } from '../../utils/supabase';
import Checkout from "../Checkout/Checkout";

const UserCourseList = ({session, account}) => {
  const [userCourses, setUserCourses] = useState([]);

  useEffect(() => {
    const fetchUserCourses = async () => {
      const { data, error } = await supabase
        .from('UserCourses') // Assuming 'UserCourses' is the table name
        .select('*')
        .eq('user_id', session.user.id); // Fetch user courses based on user_id

      if (error) console.error(error);
      else setUserCourses(data);
    };

    if (session) {
      fetchUserCourses();
    }
  }, [session]);

  const completedCourses = courses.filter(course => 
    userCourses.some(userCourse => userCourse.course_id === course.id && userCourse.complete)
  );

  const inProgressCourses = courses.filter(course => 
    userCourses.some(userCourse => userCourse.course_id === course.id && !userCourse.complete)
  );

  const notStartedCourses = courses.filter(course => 
    !inProgressCourses.some(userCourse => userCourse.course_id === course.id) &&
    !completedCourses.some(userCourse => userCourse.course_id === course.id)
  );

  return (
    <>
      <CustomNavbar title="Hourandcode" session={session} showProgress={false} />
      <div className="container col-6 mt-4">
        <h1 className="mb-4 mt-4">Courses</h1>

        { session && session.user && (account && (account.stripe_subscription_status === 'active')) && (
          <>
            <h3>In Progress</h3>
              <div className="course-list">
                {inProgressCourses.map((course) => (
                  <div className="course-card shadow-sm" key={course.id}>
                    <img src={course.thumbnail} alt={`${course.title} thumbnail`} className="course-thumbnail" />
                    <div className="course-info">
                      <h2>{course.title}</h2>
                      <p>{course.description}</p>
                      <Link to={`/courses/${course.id}`}>
                        <button className="btn btn-dark">Continue</button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
      
              <h3>{  completedCourses.length > 0 ? 'Complete' : ''}</h3>
              <div className="course-list">
                {completedCourses.map((course) => (
                  <div className="course-card shadow-sm" key={course.id}>
                    <img src={course.thumbnail} alt={`${course.title} thumbnail`} className="course-thumbnail" />
                    <div className="course-info">
                      <h2>{course.title}</h2>
                      <p>{course.description}</p>
                      <Link to={`/courses/${course.id}`}>
                        <button className="btn btn-primary">View</button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
      
              <h3>Not Started</h3>
              <div className="course-list">
                {notStartedCourses.map((course) => (
                  <div className="course-card shadow-sm" key={course.id}>
                    <img src={course.thumbnail} alt={`${course.title} thumbnail`} className="course-thumbnail" />
                    <div className="course-info">
                      <h2>{course.title}</h2>
                      <p>{course.description}</p>
                      <Link to={`/courses/${course.id}`}>
                        <button className="btn btn-primary">Start →</button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
          </>
        )}


        { session && session.user && (!account || account.stripe_subscription_status !== 'active') &&
          <>
            <Checkout className="mt-5" session={session} />
          </>
        }
        
      </div>
    </>
  );
};

export default UserCourseList;
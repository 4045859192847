import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import './quizzContentStyles.css';
import '../../../styles/prism-monokai.css'; // Import the local Monokai theme CSS

const QuizzContent = ({ step   }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [hasPassed, setHasPassed] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setErrorMessage('');
  };

  // const handleSubmit = () => {
  //   setErrorMessage('');

  //   const isMatch = checkInputMatch(); // Check if the input matches the expected result
  //   const error = isMatch ? '' : getErrorMessage(); // Get error message if input is incorrect

  //   setErrorMessage(error);

  //   if (error === '') {
  //     const progressUpdate = {
  //         input: selectedOption,
  //         result: isMatch, // This will be true or false based on the input check
  //     };
  //     // if (session || session?.user) {
  //     //   // Store progress in Supabase
  //     //   const { error: updateError } = await supabase
  //     //       .from('UserCourseLessons')
  //     //       .upsert({
  //     //           user_course_id: userCourse.id, // Ensure userCourseData is available
  //     //           lesson_id: step.id, // Assuming step.id is the lessonId
  //     //           progress: { [stepIndex]: progressUpdate }
  //     //       });

  //     //   if (updateError) {
  //     //       console.error("Error updating progress:", updateError);
  //     //       return; // Exit if there's an error
  //     //   }
  //     // }
  //     // Call onComplete with the progress update
  //     setHasPassed(true);
  //     onComplete(progressUpdate); // Ensure this is always called with a valid object
  //     }
  //   };

  //   setHasPassed(true);
  //   onComplete(progressUpdate);

  //   window.scrollTo(0, document.body.scrollHeight);
  // };

  const checkInputMatch = () => {
    const expectedResults = Array.isArray(step.expectedResult) ? step.expectedResult : [step.expectedResult];
    
    // console.log("inputVaisLessonInProgresslue.trim(): " + inputValue.trim())

    return expectedResults.includes(inputValue.trim());
  };

  const getErrorMessage = () => {
    const errorMessages =  step.errorMessages || {}; 
    return errorMessages[inputValue.trim()] || "Incorrect. Try again.";
  };

  const PassIcon = (
    <svg aria-label="Pass Icon" aria-hidden="false" className="ml-2" height="24" role="img" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <title>Pass Icon</title>
      <circle cx="12" cy="12" r="12" fill="#4CAF50" />
      <path d="M8.75 17.882l-4.359-4.359a.75.75 0 0 1 1.06-1.06l4.006 4.005 9.005-9.006a.75.75 0 0 1 1.061 1.061l-9.359 9.359a1 1 0 0 1-1.414 0z" fill="#FFF" />
    </svg>
  );

  const ReturnIcon = (
    <svg aria-label="Return Icon" aria-hidden="false" className="ml-1" height="24" role="img" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <title>Return Icon</title>
      <g aria-hidden="true">
        <path d="M16.25 5a.75.75 0 0 1 .75.75v9.5a.75.75 0 0 1-.648.743L16.25 16H7.637l1.068 1.069a.75.75 0 0 1-1.06 1.06L5.22 15.706a.75.75 0 0 1 0-1.06l2.424-2.425a.75.75 0 0 1 1.061 1.06L7.483 14.5H15.5V5.75a.75.75 0 0 1 .75-.75z" fill="#FFF"></path>
      </g>
    </svg>
  );

  return (
    <div className="quizz-content mb-3 p-3 shadow-sm rounded">
      <pre>
      <div>
        {step.content}
        {step.options.map((option, index) => (
          <div key={index}>
            <input
              type="radio"
              id={`option-${index}`}
              name="quizz-option"
              value={option}
              onChange={handleOptionChange}
            />
            <label htmlFor={`option-${index}`}>{option}</label>
          </div>
        ))}
      </div>
      </pre>
      <div className="result-section">
        <p className="quizz-output">Correct Answer</p>
        <hr/>
        {showResult ? (
          <div className="submitted-state d-flex align-items-center">
            <span dangerouslySetInnerHTML={{ __html: step.expectedResult.replace(/\n/g, '<br />') }} />
            {PassIcon}
          </div>
        ) : (
          <>
            <p>{step.description}</p>
            <Button className="ml-2 d-flex align-items-center" onClick={handleSubmit}>
              Submit
              {ReturnIcon}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default QuizzContent;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../Navbar/CustomNavbar';
import './pricingStyles.css';
import Plan from './Plan';

const Pricing = ({session}) => {
  const navigate = useNavigate();

  return (
    <div style={{padding: '20px'}}>
      <CustomNavbar title="Hourandcode" session={session} showProgress={false} onBack={() => navigate(`/courses`)}  />
      
      <div className="container mt-2 text-center">
        <h1 className="mb-3">Start learning and coding immediately. Cancel any time.</h1>
        <p>Our Monthly subscription gives you access to our entire Course Catalog.</p>
      </div>
      <Plan />
    </div>
  );
};

export default Pricing;
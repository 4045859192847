import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomNavbar from '../Navbar/CustomNavbar';
import './authenticationStyles.css';
import { supabase } from '../../utils/supabase';
import { Helmet } from 'react-helmet';

const Authentication = ({session}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleLogin = async (email) => {
    try {
      setLoading(true)
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: process.env.PUBLIC_URL,
        },
      })
      if (error) throw error
      alert('Check your email for the login link!')
    } catch (error) {
      alert(error.error_description || error.message)
    } finally {
      setLoading(false)
    }
  }


  // const handleSignInWithGoogle = async (response) => {
  //   const { data, error: signInError } = await supabase.auth.signInWithIdToken({
  //     provider: 'google',
  //     token: response.credential,
  //   });
  
  //   if (signInError) {
  //     console.error('Error signing in with Google:', signInError);
  //     error = 'Error signing in with Google. Please try again.'; // Set error message
  //   } else {
  //     console.log('User data:', data);
  //     window.location.href = '/courses'; // Redirect after successful sign-in
  //   }
  // };


  // const siteURL = "https://localhost:3000"; // Replace with your actual site URL
  // const tokenHash = "your_token_hash"; // Replace with the actual token hash
  // const magicLinkURL = `${siteURL}/auth/confirm?token_hash=${tokenHash}&type=magiclink`;

  // const handleMagicLinkNavigation = () => {
  //   navigate(magicLinkURL);
  // };

  async function signInWithGoogle() {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        prompt: 'select_account',
      },
    });

    if (data) {
      // console.log("data: ", data);
    }

    if (error) {
      // console.log("error: ", error);
    }
  }

  // async function signout() {
  //   const { error } = await supabase.auth.signOut();
  // }


  // const signInWithEmail = useCallback(async () => {
  //   const { data, error } = await supabase.auth.signInWithOtp({
  //     email: 'example@email.com',
  //     options: {
  //       shouldCreateUser: false,
  //       emailRedirectTo: 'https://example.com/welcome',
  //     },
  //   });

  //   if (error) {
  //     console.error('Error signing in with email:', error);
  //     setError('Error signing in with email. Please try again.'); // Set error state
  //   }
  // }, []);
  

  return (
    <div style={{
      padding: '20px',
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      flexDirection: 'column'
    }}>
      <Helmet>
        <script>
          {`
            gtag('event', 'conversion', {
                'send_to': 'AW-16703564964/7DQkCL6ygNMZEKTZ8Jw-',
                'value': 1.0,
                'currency': 'CAD'
            });
          `}
        </script>
      </Helmet>

      <CustomNavbar title="Hourandcode" session={session} showProgress={false} onBack={() => navigate(`/courses`)}  />
      

      <div className="mt-5">
        <h1>Sign up for free 🤗</h1>
        <p>No credit card needed to start.</p>
      </div>

      <div className="auth-box col-lg-4 col-12" style={{ 
        backgroundColor: 'white', 
        border: '1px solid #ccc', 
        borderRadius: '8px', 
        padding: '20px', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
        textAlign: 'center' 
      }}>
      
        <div>
          <button
            className="btn btn-primary w-100"
            onClick={signInWithGoogle} // Assuming you have a function for Google sign-in
          >
            Sign in with Google
          </button>
        </div>
        
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          margin: '20px 0' 
        }}>
          <hr style={{ flex: 1, border: 'none', borderTop: '1px solid #ccc' }} />
          <span style={{ padding: '0 10px' }}>Or, sign in with your email</span>
          <hr style={{ flex: 1, border: 'none', borderTop: '1px solid #ccc' }} />
        </div>
        
        <div>
          <input
            className="form-control"
            type="email"
            placeholder="Your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        
        <div>
          <button
            className="btn btn-success mt-2 w-100"
            onClick={(e) => {
              e.preventDefault();
              handleLogin(email);
            }}
            disabled={loading}
          >
            {loading ? <span>Loading</span> : <span>Send magic link</span>}
          </button>
        </div>
        
        <p>By signing up you agree to the Hourandcode Terms of Service and Privacy Policy.</p>
      </div>
    </div>
  );
};

export default Authentication;
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CustomNavbar from '../Navbar/CustomNavbar';
import TextContent from './TextContent/TextContent';
import InputContent from './InputContent/InputContent';
import CodeContent from './CodeContent/CodeContent';
import QuizzContent from './QuizzContent/QuizzContent';
import ImageContent from './ImageContent/ImageContent';
import ShowReference from './ShowReference/ShowReference';
import ProgressBar from '../Navbar/ProgressBar';
import Checkout from "../Checkout/Checkout";
import { Button } from 'react-bootstrap';
import { supabase } from '../../utils/supabase';
import './lessonStyles.css';

const Lesson = ({session, account}) => {

  const { courseId, levelId, lessonId } = useParams();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState(''); // Add this line
  const [courseData, setCourseData] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showReference, setShowReference] = useState(false);
  const [reset, setReset] = useState(false);
  const containerRef = useRef(null);
  const [courseProgress, setCourseProgress] = useState({}); // {{ edit_1 }}
  const lockSVG = '/images/lock.svg';
  const [currentUser, setCurrentUser] = useState(null);
  const [userCourse, setUserCourse] = useState(null);
  const [userCourseFetched, setUserCourseFetched] = useState(false);
  const [userCourseLessonFetched, setUserCourseLessonFetched] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [currentLesson, setCurrentLesson] = useState(null);
  // const [fetchedCourseId, setFetchedCourseId] = useState(null);
  // const [fetchedLevelId, setFetchedLevelId] = useState(null);
  // const [fetchedLessonId, setFetchedLessonId] = useState(null);
  const [fetchedSavedProgress, setFetchedSavedProgress] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0); // {{ edit_1 }}
  }, []); // 

  // Separate useEffect for logging courseProgress
  // useEffect(() => {
  //     console.log('courseProgress::::: ' + JSON.stringify(courseProgress));
  // }, [courseProgress]); // Only run when courseProgress changes


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Page is visible, do not reload
        setUserCourseLessonFetched(true); // Set pageLoaded to true
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  useEffect(() => {
    if (userCourseLessonFetched === true) {
      return;
    }
  
    const fetchCourseData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/course-json-public/${courseId}/courseData.json`);
        // Debug
        // const response = await fetch('http://127.0.0.1:3007/json-data');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCourseData(data);
        const level = data.levels[levelId];
        setCurrentLevel(level);
        const lesson = level.lessons.find(lesson => lesson.id === lessonId);
        setCurrentLesson(lesson);
  
        // setFetchedCourseId(courseId);
        // setFetchedLessonId(lessonId);
        // setFetchedLevelId(levelId);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };
  
    fetchCourseData();
  }, [courseId, lessonId, levelId, userCourseLessonFetched]);
  

  useEffect(() => {
    if (!currentUser) {
      const fetchUserData = async () => {
        if (session && session.user && (account && (account.stripe_subscription_status === 'active'))) {
            const { email } = session.user;

            // Query the Accounts table for the authenticated user's email
            const { data, error } = await supabase
              .from('Accounts')
              .select('paid')
              .eq('email', email)
              .single(); // Get a single record

            if (error) {
              console.error("Error fetching user data:", error);
              setCurrentUser({ paid: false }); // Default to false if there's an error
            } else if (data) {
              // console.log("data: " + JSON.stringify(data))
              setCurrentUser({ paid: data.paid }); // Set the paid status from the fetched data
            }
        } else {
            setCurrentUser({ paid: false }); // Default if session is not available
        }
      };

      fetchUserData();
    }
  }, [session, account]); // Depend on session to fetch user data when it changes


  useEffect(() => {
    if (userCourseFetched === true){
      // console.log('already fetched')
      return;
    };

    const fetchSavedProgress = async () => {
      if (fetchedSavedProgress === true) {
        // console.log('already fetched saved progress')
        return;
      }

      if (!session || !session.user) {
        return; // Exit if session is not available
      }
  
      if (userCourseFetched === false && userCourseLessonFetched ===  false) {
        // console.log('course_id: ' + courseData.id )
        const { data: userCourseData, error: userCourseError } = await supabase
          .from('UserCourses')
          .select('*')
          .eq('user_id', session.user.id) // Get user ID from session
          .eq('course_id', courseData.id) // Match with courseData.id
          .single(); // Get a single record
  
        if (userCourseError && (userCourseError.code !== 'PGRST116')) {
          console.error("Error fetching UserCourse:", userCourseError);
          return; // Exit if there's an error
        } else if (userCourseError && (userCourseError.code === 'PGRST116')) {
          // console.log("No UserCourse found, creating a new one.");
          // Create UserCourse if not found
          const { error: insertError } = await supabase
            .from('UserCourses')
            .insert([{ user_id: session.user.id, course_id: courseData.id, title: currentLesson.title, completed: false }]);
  
          if (insertError) {
            console.error("Error creating UserCourse:", insertError);
            return; // Exit if there's an error
          }
        }
  
        // console.log('userCourseData::: ' +  JSON.stringify(userCourseData))
        if (userCourseData) {
          setUserCourseFetched(true);
          // console.log('useCourseData already created')
          // Set userCourse to the retrieved data
          setUserCourse(userCourseData);
  
          // Now fetch the saved progress from UserCourseLessons
          console.log()
          const { data, error: progressError } = await supabase
            .from('UserCourseLessons')
            .select('*')
            .eq('user_course_id', userCourseData.id) // Use the UserCourse ID
            .eq('lesson_id', lessonId)
            .eq('user_id', session.user.id);
  
          if (progressError  && (progressError.code !== 'PGRST116')) {
            console.error("Error fetching saved progress:", progressError);
            return; // Exit if there's an error
          } else if (progressError && (progressError.code === 'PGRST116')) {
            console.log('error PGRST116')
          }
  
          if (data && data.length > 0) {
            setUserCourseLessonFetched(true);
  
            const progress = data[0].progress || {};
            const initialStep = Object.keys(progress).filter(key => key !== 'progress').length || 0;
            setCurrentStep(initialStep);
            setCourseProgress(progress);
            // setProgressData(progress);
          } else {
            console.log("No UserCourseLesson found, creating a new one.");
  
            // Create UserCourse if not found
            const { error: insertError } = await supabase
              .from('UserCourseLessons')
              .insert([{
                user_id: session.user.id,
                user_course_id: userCourseData.id,
                lesson_id: lessonId,
              }]);
  
            if (insertError) {
              console.error("Error creating UserCourseLesson:", insertError);
              return; // Exit if there's an error
            }
            // Handle case where no progress is found
            setCurrentStep(0);
            setCourseProgress({});
            setFetchedSavedProgress(true)
          }
        }  else {
          // console.log('nooo userCourseData')
        }
      }
    };

    const checkSession = async () => {
      const { data: sessionData } = await supabase.auth.getSession();
      if (sessionData) {

        if (courseData) {
          // Fetch progress from Supabase when courseData is available
          fetchSavedProgress();
        }
      } else {
        navigate('/authentication');
      }
    };

    checkSession();
  }, [session, navigate, courseData, courseId, levelId, lessonId, reset, currentLesson?.title, userCourseFetched, userCourseLessonFetched]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [currentStep]);

  const handleCompleteStep = async (progressUpdate) => {
    const { result, input, error } = progressUpdate;

    if (session || session?.user) {
      // Update UserCourseLesson is session exists
      // console.log('userCourse: ' + JSON.stringify(userCourse))
      const { data, error: lessonError } = await supabase
        .from('UserCourseLessons')
        .select('*')
        .eq('user_course_id', userCourse.id) // Use the UserCourse ID
        .eq('lesson_id', lessonId)
        .eq('user_id', session.user.id)
        .single();

      if (lessonError && (lessonError.code !== 'PGRST116') ) {
        console.error("Main: Error fetching lesson progress:", lessonError);
      } else if (lessonError && lessonError.code === 'PGRST116' ){
        console.log('404 – The result contains 0 rows');
      } else if (data) {
        // console.log('updating progress.....')
        // Update existing lesson progress
        try {
          // Update existing lesson progress
          const { error: updateError } = await supabase
            .from('UserCourseLessons')
            .update({
              progress: { ...data.progress, [currentStep]: { result, input, error } }
            })
            .eq('id', data.id);

          if (updateError) {
            throw new Error(`Error updating lesson progress: ${updateError.message}`);
          }
        } catch (err) {
          console.error(err);
          // Handle the error (e.g., show a notification to the user)
          return; // Exit if there's an error
        }
      } else {
        console.error("No lesson progress found for update.");
      }
    }
   

    // Update isCorrect based on the result
    if (result) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
    }
  };

  const handleContinue = () => {
    // Always call onComplete regardless of correctness for "Continue"
    const progressUpdate = {
        input: inputValue,
        result: true, // Assume the user wants to continue
    };

    containerRef.current.scrollTop = containerRef.current.scrollHeight;

    handleCompleteStep(progressUpdate);
  };

  const handleToggleReference = () => {
    setShowReference(!showReference);
  };

  const handleLastStep = async () => {
    if (session && session.user && (account && (account.stripe_subscription_status === 'active'))) {
      const { data: userCourseData, error: userCourseError } = await supabase
          .from('UserCourses')
          .select('*')
          .eq('user_id', session.user.id)
          .eq('course_id', courseId)
          .single();

      if (userCourseError) {
          console.error("Error fetching UserCourse:", userCourseError);
          return; // Exit if there's an error
      }

      const { error } = await supabase
        .from('UserCourses')
        .update({
          lessons_completed: {
            ...userCourseData.lessons_completed, // Spread existing completed lessons
            [lessonId]: true // Set the current lessonId to true
          }
        })
        .eq('user_id', session.user.id) // Ensure to update the correct user
        .eq('course_id', courseId) // Ensure to update the correct course
        .single();

      // console.log('lessonId: ' + JSON.stringify(lessonId))

      if (error) {
        console.error("Error updating lesson completion status:", error);
        return; // Handle the error as needed
      } else {
        try {
          // Update existing lesson progress
          const { error: updateError } = await supabase
            .from('UserCourseLessons')
            .update({
              completed: true
            })
            .eq('lesson_id', lessonId)
            .eq('user_course_id', userCourseData.id)
            .eq('user_id', session.user.id) 
            .single()

          if (updateError) {
            throw new Error(`Error updating lesson completed state: ${updateError.message}`);
          }
        } catch (err) {
          console.error(err);
          // Handle the error (e.g., show a notification to the user)
          return; // Exit if there's an error
        }
      }
    }
    
    handleCompleteStep({result: true});
  };

  const handleReset = async () => {
    if (!session || !session.user) {
      return; // Exit if session is not available
    }
  
    // Fetch UserCourse based on course_id
    const { data: userCourseData, error: userCourseError } = await supabase
      .from('UserCourses')
      .select('*')
      .eq('user_id', session.user.id)
      .eq('course_id', courseId)
      .single();
  
    if (userCourseError) {
      console.error("Error fetching UserCourse:", userCourseError);
      return; // Exit if there's an error
    }
  
    if (userCourseData) {
      // Fetch UserCourseLessons based on UserCourse ID and lesson ID
      const { data: lessonData, error: lessonError } = await supabase
        .from('UserCourseLessons')
        .select('*')
        .eq('user_course_id', userCourseData.id)
        .eq('lesson_id', lessonId)
        .single();
  
      if (lessonError) {
        console.error("Error fetching lesson progress:", lessonError);
        return; // Exit if there's an error
      }
  
      if (lessonData) {
        // Reset the progress in UserCourseLessons
        const { error: updateError } = await supabase
          .from('UserCourseLessons')
          .update({ progress: {} }) // Reset progress to an empty object
          .eq('id', lessonData.id);
  
        if (updateError) {
          console.error("Error resetting lesson progress:", updateError);
        }
      }
    }
  
    setReset(true);
    setTimeout(() => {
      setReset(false);
      setCurrentStep(0); // Reset current step to 0
    }, 0);
  };


  // Ensure courseData is loaded before accessing it
  if (!courseData) {
    // console.log("Course data is not available yet, rendering loading message.");
    return <div>Loading...</div>;
  }

  // Check if levels exist

  if (!currentLevel) {
    console.error("Level not found for levelId:", levelId);
    return <div>Error: Level not found.</div>;
  }

  // Check if lessons exist
  if (!currentLesson) {
    console.error("Lesson not found for lessonId:", lessonId);
    return <div>Error: Lesson not found.</div>;
  }

  // Check if lesson has steps
  if (!currentLesson.steps) {
    console.error("Steps not found for lesson:", currentLesson);
    return <div>Error: Steps not found for this lesson.</div>;
  }

  // Now you can safely access steps
  // const currentStepData = lesson.steps[currentStep];
  // if (!currentStepData) {
  //   console.error("Current step not found:", currentStep);
  //   return <div>Error: Current step not found.</div>;
  // }

  const isLastStep = (currentStep === currentLesson.steps.length - 1);
  const isCourseCompleted = currentStep > currentLesson.steps.length - 1;
  // console.log('isCourseCompleted: ' + isCourseCompleted)
  const progress = isLastStep ? 100 : ((currentStep + 1) / currentLesson.steps.length) * 100;

  const userCanView = (lesson) => {
    if (lesson.locked === 'true') {
      if (session && session && session.user && (currentUser && currentUser.paid === true)) {
        return true;
      } else {
        return false;
      }
    } else {
     return true;
    }
  }

  // console.log("Course Data:", courseData);
  // console.log("Level ID:", levelId);
  // console.log("Lesson ID:", lessonId);
  // console.log("Current Step:", currentStep);
  
  return (
    <>
      <CustomNavbar progress={progress} session={session} onBack={() => navigate(`/courses/${courseId}`)} />
      {progress && (
        <div className="progress-container-mobile d-block d-md-none mt-2">
          <span>Progress:</span>
          <ProgressBar progress={progress} />
        </div>
      )}
      <div className="container col-md-6 col-12 mt-4" ref={containerRef}>
        <div className="d-block d-md-block" style={{ paddingTop: '4rem' }}></div> {/* Spacer for mobile */}
        <div className="d-block d-md-none" style={{ paddingTop: '4rem' }}></div> {/* Spacer for mobile */}
        <span className="badge badge-dark">✨ premium</span>
        <h1>{currentLesson.title}</h1>
        {userCanView(currentLesson) && (
          <>
            <div className="app-container  mt-4 pt-4 pt-md-0">
              {currentLesson.steps.slice(0, currentStep + 1).map((step, index) => (
                <div key={index} className="step-container">
                    {/* <h1>{index}</h1> */}
                    {step.type === 'text' && (
                      <div>
                        <TextContent content={step.content} />
                        {index === currentStep && !isLastStep && (
                          <Button className="mt-2" onClick={handleContinue}>
                            Continue
                          </Button>
                        )}
                      </div>
                    )}
                    {step.type === 'input' && (
                      <InputContent
                        inputValue={inputValue} 
                        setInputValue={setInputValue} 
                        onComplete={handleCompleteStep}
                        courseId={courseId}
                        reset={reset}
                        stepIndex={index}
                        step={step}
                        courseProgress={courseProgress}
                        isCorrect={courseProgress[index]?.["result"] || false}
                        session={session}
                        lessonId={lessonId}
                      />
                    )}
                    {step.type === 'code' && (
                      <CodeContent 
                          step={step} 
                          onComplete={handleCompleteStep} // Ensure this is passed correctly
                          courseId={courseId}
                          levelId={levelId}
                          lessonId={lessonId}
                          stepIndex={index} 
                          reset={reset}
                          session={session}
                      />
                    )}
                    {step.type === 'quizz' && (
                      <QuizzContent 
                          step={step} 
                          onComplete={handleCompleteStep} // Ensure this is passed correctly
                          courseId={courseId}
                          levelId={levelId}
                          lessonId={lessonId}
                          stepIndex={index} 
                          reset={reset}
                          session={session}
                      />
                    )}
                    {step.type === 'image' && (
                      <ImageContent 
                          src={step.src} 
                          alt={step.alt} 
                      />
                    )}
                  </div>
                ))}
                {isLastStep && (
                  <Button className="mt-2" onClick={handleLastStep}>
                    Finish Lesson
                  </Button>
                )}
                
                {isCourseCompleted && (
                  <>
                    <p><b>Congratulation! You've completed this lesson
                      .</b></p>
                    { (session && session.user) && (
                      <Button className="mt-2 mb-4" onClick={() => navigate(`/courses/${courseId}`)}>
                        Go Back to Course Homepage
                      </Button>
                    )}
                    { (!session || !session.user) && (
                      <div className="mt-4 mb-4">
                        <p>You've just completed this lesson, but your progress won't be saved without an account. With an account, you can track your progress, unlock all premium lessons, and continue where you left off. Create your account now to enjoy all the benefits!</p>
                        <div className="pricing-container">
                            <Button className="unlock-button" onClick={() => navigate(`/upgrade`)}>Unlock Full Access</Button>
                            <span className="pricing-text">Starting at $69/mo</span>
                        </div>   
                      </div>
                    )}
                  </>
                )}
              <br/><br/>
            </div>
            <Button
              className="btn btn-mobile-top btn-primary position-fixed bottom-0 end-0 m-3 show-reference-btn"
              onClick={handleToggleReference}
            >
              {showReference ? 'Hide Reference' : 'Show Reference'}
            </Button>
              {showReference && (
                <>
                  <div className="dimmed-background"></div>
                  <div className="reference-popup">
                    <ShowReference />
                  </div>
                </>
              )}
            <Button
              className="btn btn-danger position-fixed bottom-0 start-0 m-3"
              onClick={handleReset}
            >
              Reset Lesson
            </Button>
          </>
        )}

        {!userCanView(currentLesson) && (
          <>
            <div className="app-container  mt-0 pt-4">
              <h5 className="upgrade-notice p-2 mb-0 bg-white text-center">⭐️ Premium Access Required<span><img src={lockSVG} alt="lock" className="lock"/></span></h5>
              <Checkout session={session} />
            </div>
          </>
        )}
        
      </div>
    </>
  );
};

export default Lesson;